import './css/base-textarea.scss';

export const BaseTextarea = function ({ name, label, value = '', onInput, customClass='w100' }) {
  return (
    <label htmlFor={name} cy={name} className="base-textarea block">
      <b className={customClass!='w100' && 'grey'} dangerouslySetInnerHTML={{ __html: label || name }} />
      <br />
      <textarea
        className={customClass}
        value={value}
        onInput={(ev) => onInput({ key: name, value: ev.target.value })}
      />
    </label>
  );
};
